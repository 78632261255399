<template>
  <CRow class="mt-2">
    <CCol sm="12">
      <CTabs :active-tab="activeTab" @update:activeTab="handleTab">
        <CTab :title="$t('label.workShifts')">
          <index-work-shift :active="isActiveTab(0)" :tap="active" class="ml-1 mr-1"/>
        </CTab>
        <CTab :title="$t('label.WorkShiftsPerVesselType')">
          <index-shift-vessel-type :active="isActiveTab(1)" class="ml-1 mr-1"/>
        </CTab>
      </CTabs>
    </CCol>
  </CRow>
</template>

<script>

import indexWorkShift from './index-work-shift';
import IndexShiftVesselType from './index-shift-vessel-type';

//Funcion data() del ciclo.


function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    activeTab: 0,

    isLoading: false,

  };
}
//Methods:
function handleTab(tab) {
  this.activeTab = tab;
}

function isActiveTab(current) {
  return this.activeTab == current;
}

export default {
  name: 'turnos',
  components: {
    indexWorkShift,
    IndexShiftVesselType
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data,
  methods: {
    handleTab,
    isActiveTab
  },
  computed: {  },
  watch: {
    active: function(newVal) {
      if(newVal == 0)
        this.activeTab = 0;
    }
  }
}
</script>

<style scoped>
</style>